import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageHeader } from "../utils"
import contactImg from "../images/BGgamepads28.png"
import styled from "styled-components"
import { styles } from "../utils"
const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <PageHeader img={contactImg}>
      <FormWrapper>
        <div class="container">
          <form
            id="contact"
            name="contact"
            method="post"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <p style={{ visibility: "hidden" }}>
              <label>Don't Fill This Out If You're Human</label>
            </p>

            <input type="hidden" name="form-name" value="contact" />
            <h3>Quick Contact</h3>
            <h4>
              Have any questions, comments, or just want to say hi? Hit me up.
            </h4>
            <label>
              <input
                placeholder="Your Full Name"
                type="text"
                name="fname"
                required
              />
            </label>
            <label>
              <input
                placeholder="Your Email Address"
                type="email"
                name="email"
                required
              />
            </label>

            <label>
              <textarea
                name="message"
                type="text"
                placeholder="Type your Message Here...."
                required
              ></textarea>
            </label>
            <label>
              <button name="submit" type="submit">
                Submit
              </button>
            </label>
          </form>
        </div>
        <div data-netlify-recaptcha="true"></div>
      </FormWrapper>
    </PageHeader>
  </Layout>
)
export default ContactPage

const FormWrapper = styled.div`
  .container {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  label {
    border: medium none !important;
    margin: 0 0 10px;
    min-width: 100%;
    padding: 0;
    width: 100%;
  }

  #contact {
    background: #161823;
    padding: 25px;
    margin: 50px 0;
  }
  #contact h3 {
    color: ${styles.colors.mainPink};
    display: block;
    font-size: 25px;
    font-weight: 400;
  }

  #contact h4 {
    margin: 2rem 0 1rem;
    display: block;
    font-size: 13px;
    line-height: 1.8rem;
  }
  #contact input[type="text"],
  #contact input[type="email"],
  #contact textarea {
    width: 100%;
    border: 1px solid #ccc;
    background: #fff;
    margin: 0 0 5px;
    padding: 10px;
  }

  #contact input[type="text"]:hover,
  #contact input[type="email"]:hover,
  #contact textarea:hover {
    -webkit-transition: border-color 0.3s ease-in-out;
    -moz-transition: border-color 0.3s ease-in-out;
    transition: border-color 0.3s ease-in-out;
    border: 1px solid #aaa;
  }

  #contact textarea {
    height: 100px;
    max-width: 100%;
    resize: none;
  }

  #contact button[type="submit"] {
    cursor: pointer;
    width: 100%;
    ${styles.border({ color: `${styles.colors.mainWhite}` })};
    //border: none;
    background: transparent;
    color: ${styles.colors.mainWhite};
    margin: 0 0 5px;
    padding: 1rem 1rem 1rem 1.5rem;
    font-size: 1rem;
    display: center;
    margin-top: 2rem;
    ${styles.transition({})};

    &:hover {
      background: ${styles.colors.mainPink};
      color: ${styles.colors.mainBlack};
    }

    #contact button[type="submit"]:hover {
      background: #09c;
      -webkit-transition: 0.3s ease-in-out;
      -moz-transition: 0.3s ease-in-out;
      transition: background-color 0.3s ease-in-out;
    }

    #contact button[type="submit"]:active {
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
    }

    #contact input:focus #contact textarea:focus {
      outline: 0;
      border: 1px solid #999;
    }
    ::-webkit-input-placeholder {
      color: #888;
    }
    :-moz-placeholder {
      color: #888;
    }
    ::-moz-placeholder {
      color: #888;
    }
    :-ms-input-placeholder {
      color: #888;
    }
  }
`
